import React from 'react';
import pdf from '../../static/documents/PrivatePolicyIntake_210601.pdf'

export class Pdf4 extends React.Component {
  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.open(pdf)
    }
  }

  render() {
    return <></>
  }
}

export default Pdf4;
